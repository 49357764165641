import {useEffect} from 'react';
import Script from "next/script";
import App from 'next/app';
import {ThemeProvider} from '@material-ui/core/styles';
import {themeExtender} from '@/theme';
import { CookiesProvider } from "react-cookie";
import ErrorBoundary from "@/components/01_atoms/ErrorMessage/ErrorBoundary";
import HtmlHead from '@/components/01_atoms/HtmlHead';
import ErrorMessage from '@/components/01_atoms/ErrorMessage';
import SiteLayout from '@/components/04_templates/PageLayouts/GlobalLayout';
import '@/components/01_atoms/PageProgressBar';

import CurrentPageContext from "@/components/context/CurrentPageData";
import * as globalAPI from '@/api/global';
import * as transforms from "@/utils/transforms";
import "react-placeholder/lib/reactPlaceholder.css";
import '@/styles/globals.scss';
import '@/styles/baywoods.scss';
import { GTM_ID } from '@/helpers/gtm';
import fetchMenu from '../utils/fetchMenu';

const axios = require('axios');
const sitewideContext = {
  site: 'baywoods',
}

let curr_page = {};

MyApp.getInitialProps = async (appContext) => {
  const {Component, ctx} = appContext;

  const initialProps = {
    page: {},
    menu: {},
    footermenu: {},
    metatags: [],
    blocks: {},
    isServer: !!ctx.req,
    statusCode: 200
  };

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  curr_page = appProps?.pageProps?.page;

  try {
    let assemble_page, byPath, ent_type;
    if (initialProps.isServer && !ctx?.pathname?.includes('error') && !ctx?.pathname?.includes('/404')) {
      byPath = ctx.asPath || ctx.req.url;

      // let byPath = ctx.req?.url.includes('_next') ? ctx.asPath : ctx.req.url;
      if (byPath && !byPath.includes('[') && !byPath.includes('_next')) {

        // At least identify article categories or internal paths for terms. So that
        // route resolving by path is constructed properly next.
        ent_type = (byPath?.includes('escapia-amenities/') || (ctx.pathname?.includes('[category]') || ctx.query?.category || ctx.query?.tid) && !ctx.query?.blog && !ctx.query?.node && !ctx.query?.nid) ? 'term' : false;
        assemble_page = globalAPI.getGlobalElementsLookup(byPath, ent_type, 'page');
      }
    }
    console.log('assemble_page', assemble_page?.getUrl(), assemble_page)

    if (assemble_page) {
      let [menu_data, meta_data] = await Promise.all([
        fetchMenu(byPath, ent_type, 'menus'),
        fetch(assemble_page.getUrl(), {
          cache: 'force-cache',
          headers: {
            "Accept": "multipart/related"
          },
        })
      ]);
      meta_data = await meta_data.json();
      const node_resp = {...menu_data, ...meta_data};
      // console.log('Page meta respo 2', node_resp);

      if (node_resp) {
        Object.keys(node_resp).map((res) => {
          let json = null;
          if (typeof node_resp[res]?.body === 'string') {
            try {
              json = JSON.parse(node_resp[res]?.body);
            } catch (e) {
              console.warn('Could not parse body', e);
            }
          }
          // if (res[i]?.body?.startsWith('{')) {
          //   json = JSON.parse(res[i].body);
          // }
          // console.log('Node resp loop', res, res.includes('pagemenu'), json);
          if (json && res.includes('pagemeta')) {
            initialProps.metatags = transforms.metadata(json.data);
          }
          else if (json && res.includes('pagemenu')) {
            initialProps.menu = json.data;
          }
          else if (json && res.includes('footermenu')) {
            initialProps.footermenu = json.data;
          }
          else if (json && res.includes('pageblocks')) {
            initialProps.blocks = json.data;
          }
          else if (json && res.includes('pageroute')) {
            initialProps.page = json;
            sitewideContext['page_info'] = json;
          }
          else if (!json) {
            console.error("Could not parse response as JSON in " + res + " request.");
          }
        });
      }
    }
  }
  catch (e) {
    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;
    console.error('Application error: ', e);

    // In case of Server Side rendering we want the server to throw the
    // correct error code.
    if (ctx) {
      ctx.statusCode = 500;
    }
  }

  appProps.pageProps = {
    ...initialProps,
    ...appProps.pageProps
  };

  return appProps;
};

function MyApp({Component, pageProps}) {
  useEffect(() => {
    document.body.classList.add('baywoods');
    if (Component?.name == 'Home') {
      document.body.classList.add('homepage');
    }
  })

  // https://dev.to/felixmohr/setting-up-a-blog-with-next-js-react-material-ui-and-typescript-2m6k
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const {menu, metatags, footermenu, blocks} = pageProps;
  const pageLayout = '';

  const statusCode = pageProps.statusCode || 200;
  const meta = metatags?.tags || [];

  return (
    <>
      {/*<Script key={`jquery-ext`} id={`jquery-3.6.0`}*/}
      {/*        src={`https://code.jquery.com/jquery-3.6.0.min.js`} async={false}*/}
      {/*        onError={() => {console.warn('jquery 3.6.0 load error');}}*/}
      {/*/>*/}
      <Script strategy="afterInteractive" className={`gtm-tag-js`} id={"gtm-script"} async={`async`}
              dangerouslySetInnerHTML={{__html: `(function(w,d,s,l,i){w[l]=(w[l]||[]);w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer',"${GTM_ID}");`,
              }}
      />
      <ThemeProvider theme={themeExtender}>
        <CookiesProvider>
          <CurrentPageContext.Provider value={sitewideContext}>
            <HtmlHead metatags={meta}/>
            {/* .Setting up the Noteefy widget on Baywood Website */}
            <div id="noteefy-widget" style={{ display: 'block' }}>
              <Script id="noteefy-js" key="noteefy-js" strategy="lazyOnload" src="https://noteefypublic.blob.core.windows.net/widget/DEBaywoodGreensGolfCourse/DEBaywoodGreensGolfCourseWidget.js" />
            </div>
            <SiteLayout menu={menu} footermenu={footermenu} blocks={blocks} layout={pageLayout}>
              {statusCode <= 399
              && <ErrorBoundary><Component {...pageProps} /></ErrorBoundary>
              }
              {statusCode > 399
              && <ErrorMessage statusCode={statusCode}/>
              }
            </SiteLayout>
          </CurrentPageContext.Provider>
        </CookiesProvider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
