import { LRUCache } from 'lru-cache';
import * as globalAPI from '@/api/global';

const cache = new LRUCache({
  max: 1,           // Store 1 menu object
  ttl: 12 * 60 * 60 * 1000,   // Cache for 12 hours (in ms)
});

export default async function fetchMenu(byPath, ent_type, partial = null) {
  if (cache.has('menu')) {
    return cache.get('menu');
  }

  let assemble = globalAPI.getGlobalElementsLookup(byPath, ent_type, partial);

  const res = await fetch(assemble.getUrl(), {
    cache: 'force-cache',
    headers: {
      "Accept": "multipart/related"
    },
  });
  const data = await res?.json();
  cache.set('menu', data);
  return data;
}
